import React, { useState, useEffect, useRef } from 'react'
// import Menu from '../../components/Menu/Menu'
import { toast } from 'react-toastify'
import { getUserSettings, updateUserSettings } from '../../services/SettingsService'
import { i18n } from '../../utils/translates/i18n'

export default function Profile() {
  const confirmPassword = useRef('')
  const [settings, setSettings] = useState({})

  useEffect(() => {
    getUserSettings()
      .then(result => {
        if (result.status) setSettings(result.plain)
      })
      .catch(err => {
        console.error(err.response ? err.response.data : err.message)
        toast.error(err.response ? err.response.data : err.message)
      })
  }, [])

  function onInputChange(event) {
    setSettings(prevState => ({ ...prevState, [event.target.id]: event.target.value }))
  }

  function onFormSubmit(event) {
    if ((settings.password || confirmPassword.current.value) && settings.password !== confirmPassword.current.value)
      return toast.error(`The fields New Password and Confirm Password must be equal.`)

    updateUserSettings(settings)
      .then(result => {
        if (result) toast.success(`Settings saved successfully!`)
        else toast.error(result)
      })
      .catch(err => {
        console.error(err.response ? err.response.data : err.message)
        toast.error(err.response ? err.response.data : err.message)
      })
  }

  return (
    <React.Fragment>
      {/* <Menu /> */}
      <main className="content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h1 className="h4">{i18n.t('settings')}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card card-body border-0 shadow mb-4">
              <h2 className="h5 mb-4">{i18n.t('personal')}</h2>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="email">{i18n.t('email')}</label>
                    <div className="form-control">{settings.email}</div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="phone">{i18n.t('phone')}</label>
                    <input
                      className="form-control"
                      id="phone"
                      type="text"
                      placeholder="+1 51 123456789"
                      defaultValue={settings.phone}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="password">{i18n.t('new_password')}</label>
                    <input
                      className="form-control"
                      id="password"
                      type="password"
                      placeholder={i18n.t('enter_your_new_password')}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div>
                    <label htmlFor="confirmPassword">{i18n.t('confirm_new_password')}</label>
                    <input
                      ref={confirmPassword}
                      className="form-control"
                      id="confirmPassword"
                      type="password"
                      placeholder={i18n.t('confirm_your_new_password')}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                  <div className="col-sm-3">
                    <button className="btn btn-gray-800 mt-2 animate-up-2" type="button" onClick={onFormSubmit}>
                      {i18n.t('save')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}
