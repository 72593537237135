import { messages as arabicMessages } from './la';
import { messages as portugueseMessages } from './pt';
import { messages as englishMessages } from './en';
import { messages as espanolMessages } from './es';
import { messages as frenchMessages } from './fr';
import { messages as germanMessages } from './de';
import { messages as chineseMessages } from './cn';
import { messages as russianMessages } from './ru';

const messages = {
	...arabicMessages,
	...portugueseMessages,
	...englishMessages,
	...espanolMessages,
	...frenchMessages,
	...germanMessages,
	...chineseMessages,
	...russianMessages,
};

export { messages };
