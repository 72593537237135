import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/binaries`

export async function getPercentageBinaries(token) {
  const endPoint = `${ENDPOINT}/percentages`
  
  const response = await axios.get(endPoint)
  return response.data
}

export async function savePercentages(token, percentageBinaries) {
  const endPoint = `${ENDPOINT}/savepercentages`
  
  const response = await axios.put(endPoint, percentageBinaries)
  return response.data
}
