import { useState } from 'react';
import ItemTranslate from './ItemTranslate';

const Translate = ({ lngName, classVar }) => {
	const I18N_STORAGE_KEY = 'i18nextLng';

	const [language, setLanguage] = useState(localStorage.getItem(I18N_STORAGE_KEY));

	const handleLanguage = e => {
		setLanguage(e);
		localStorage.setItem(I18N_STORAGE_KEY, e);
		let location = window.location.toString().split('#')[0];
		window.location = location;
	};

	const langs = [
		{ name: 'عربي', ab: 'la', flag: 'la' },
		{ name: '中国人', ab: 'cn', flag: 'cn' },
		{ name: 'Deutsch', ab: 'de', flag: 'de' },
		{ name: 'English', ab: 'en-US', flag: 'us' },
		{ name: 'Español', ab: 'es', flag: 'es' },
		{ name: 'Français', ab: 'fr', flag: 'fr' },
		{ name: 'Português', ab: 'pt-BR', flag: 'pt' },
		{ name: 'Русский', ab: 'ru', flag: 'ru' },
	];

	return (
		<>
			<div className='nav-item dropdown'>
				<button
					className={`nav-link btn dropdown-toggle ${classVar}`}
					type='button'
					data-bs-toggle='dropdown'
					aria-expanded='false'>
					<img
						src={`/flags/1x1/${langs.filter(lg => lg.ab === language).map(lg => lg.flag)[0]}.svg`}
						alt={language}
						style={{ width: '30px', height: 'auto' }}
						className='rounded-circle'
					/>{' '}
					{
						langs
							.filter(lg => lg.ab === language)
							.map(lg => (lngName === 'none' ? '' : lngName === 'abrev' ? lg.ab : lg.name))[0]
					}
				</button>

				<div className='dropdown-menu  dropdown-menu-dark dropdown-menu-end'>
					{langs?.map((l, i) => (
						<ItemTranslate key={i} onClick={handleLanguage} langs={l} language={language} />
					))}
				</div>
			</div>
		</>
	);
};

export default Translate;
