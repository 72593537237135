import React from 'react'
import { FormateDate } from '../../utils/CustomFormatations'

export default function ResultRow({ result }) {
  return (
    <>
      <tr>
        <td>{result.mainLottery?.lottery?.toUpperCase()}</td>
        <td>{FormateDate(result.drawDate, true,true)}</td>
        <td>
          <div className="d-flex flex-wrap justify-content-start gap-2">
            {result.winningNumbers.split(',').map((ball, bix) => (
              <span
                className={`d-flex align-items-center justify-content-center border border-primary rounded-circle text-dark fw-bold bg-light`}
                style={{ width: '30px', height: '30px' }}
                key={bix}>
                {!!ball && ball.length > 1 ? ball : `0${ball}`}
              </span>
            ))}
          </div>
        </td>
      </tr>
    </>
  )
}
