const messages = {
  la: {
    translations: {
      a_crase: 'ال',
      about: 'معلومات عنا',
      above_the_same: 'بالضبط نفس الشيء أعلاه',
      action: 'فعل',
      actionTemplates: 'نماذج العمل',
      activate_date: 'تفعيل التسجيل',
      active_automations: 'الأتمتة النشطة',
      active_connections: 'اتصالات نشطة',
      active_monitors: 'الشاشات النشطة',
      active_users: 'المستخدمين النشطين',
      ad: 'إعلان',
      add: 'الانضمام',
      add_option: 'إضافة خيار',
      add_payment: 'إضافة الدفع',
      add_question: 'أضف سؤال',
      add_receipt: 'إضافة إيصال',
      add_wallet: 'أضف المحفظة',
      address: 'عنوان',
      admin: 'إدارة',
      advance: 'يتقدم',
      affected: 'وصل',
      after: 'بعد',
      air_transport: 'النقل الجوي',
      ajust_limite: 'تعديل الحد',
      ajust_saldo: 'تعديل الرصيد',
      alert: 'يُحذًِر',
      alerts: 'التنبيهات',
      all: 'الجميع',
      all_departments: 'جميع الإدارات',
      allies_of: 'حلفاء',
      allRightsReserved: 'كل الحقوق محفوظة.',
      almost_there: 'اوشكت على الوصول',
      already_offset: 'لقد عوضت بالفعل',
      already_registered: 'مسجل بالفعل؟ انقر هنا وأدخل',
      amount: 'قيمة',
      amount_above_balance: 'رصيد غير كاف',
      and: 'إنها',
      and_more: 'و اكثر',
      answer: 'إجابة',
      ao: 'إلى',
      approve: 'ليوافق',
      April: 'أبريل',
      asc: 'تصاعدي',
      at: 'في',
      August: 'أغسطس',
      automations: 'الأتمتة',
      awaiting_email_confirmation: 'في انتظار تأكيد البريد الإلكتروني!',
      b_order: 'طلب',
      b_orders: 'طلبات',
      back: 'للعودة',
      bairroInteresse: 'حيّ',
      balance: 'توازن',
      balance_available: 'الرصيد متاح',
      banned: 'محظور',
      before_contact: 'قبل الاتصال بنا',
      binary: 'نقطة مزدوجة',
      birth: 'ولادة',
      blind_man: 'عكس',
      body_monthly:
        'لقد انتهت صلاحية الرسوم الشهرية وتم إلغاء تنشيط BOT TRADER الخاص بك، يرجى دفع أمر الدفع والاستمتاع بمزاياك مرة أخرى',
      bonus_saldo_trader: 'مكافأة رصيد التاجر',
      book_health: 'بوك هيلث',
      but_possible_register: 'لا يزال بإمكانك التسجيل، ولكن لن يتم ربطك بأي صديق محال.',
      buy_volume: 'حجم الشراء',
      buyer: 'مشتر',
      by: 'لكل',
      bye: 'أراك لاحقًا',
      call: 'مُسَمًّى',
      call_to: 'دعوة ل',
      calls: 'المكالمات',
      cancel: 'يلغي',
      canceled: 'ألغيت',
      career_plan: 'المسار المهني',
      casa_verde_amarela: 'البيت الأخضر الأصفر',
      cash_payment: 'دفع نقدا',
      cash_payment_return: 'عودة الدفع النقدي',
      cash_receipt: 'إيصال الدفع',
      change_indicant: 'تغيير المؤشر',
      change_photo: 'غير الصوره',
      change_plan: 'خطة التغيير',
      check_payment: 'الدفع بالشيك',
      check_payment_return: 'إعادة الدفع عن طريق الشيك',
      check_receipt: 'الاستلام بالشيك',
      choose: 'لإختيار',
      cidadeInteresse: 'مدينة',
      city: 'مدينة',
      claims: 'المطالبات',
      clean: 'لينظف',
      click_and_know: 'انقر واكتشف',
      click_to_copy_the_wallet: 'انقر لنسخ المحفظة',
      client: 'عميل',
      clients: 'عملاء',
      close: 'إنهاء',
      cnh: 'CNH (ترخيص)',
      cnhDate: 'تاريخ إصدار CNH',
      cnpj: 'CNPJ',
      cnpj_invalid: 'CNPJ غير صالح',
      coin: 'عملة',
      color: 'لون',
      commercial: 'تجاري',
      company: 'اسم الشركة',
      complement: 'إطراء',
      comporRenda: 'هل ستجني المال مع شخص ما؟',
      compRenda: 'نوع إثبات الدخل',
      confirm_approve: 'تأكيد الموافقة',
      confirm_delete: 'تأكيد الحذف',
      confirm_new_password: 'تأكيد كلمة المرور الجديدة',
      confirm_new_password_security: 'تأكيد كلمة المرور الأمنية الجديدة',
      confirm_password: 'تأكيد كلمة مرور الوصول',
      confirm_password_security: 'تأكيد كلمة المرور الأمنية',
      confirm_your_new_password: 'أدخل كلمة المرور الجديدة مرة أخرى',
      confirmed: 'مؤكد',
      confirmed_email: 'البريد الإلكتروني المؤكد',
      congratulations: 'تهانينا',
      contact: 'اتصال',
      contract: 'عقد',
      copied: 'نسخ',
      copy: 'ينسخ',
      copy_success: 'تم النسخ بنجاح!',
      correspondences: 'مراسلة',
      countries: 'بلدان',
      country: 'دولة',
      cpf: 'CPF',
      cpf_invalid: 'رقم CPF غير صالح',
      create_order_success: 'تم إنشاء الطلب بنجاح!',
      created: 'مسجل في',
      cred: 'ائتمان',
      cred_binario: 'رصيد النقاط المزدوجة',
      cred_deb: 'الاعتمادات/الخصومات',
      cred_game_donate: 'لعبة التبرع بالائتمان',
      cred_ind_direta: 'رصيد الإحالة المباشر',
      cred_ind_indireta: 'رصيد الإحالة غير المباشر',
      cred_mandala: 'ماندالا الائتمان',
      cred_pagar_com_saldo: 'دفع الائتمان مع الرصيد',
      cred_pontos: 'نقاط الائتمان',
      cred_recb_trader: 'الائتمان تلقي التاجر',
      cred_renovacao: 'تجديد الائتمان',
      cred_saldo_disp: 'رصيد الائتمان المتاح',
      cred_upgrade: 'ترقية الائتمان',
      credit_card_payment: 'الدفع عن طريق بطاقة الائتمان',
      credit_card_payment_return: 'إعادة الدفع عن طريق بطاقة الائتمان',
      credit_card_receipt: 'إيصال بطاقة الائتمان',
      current: 'حاضِر',
      current_password: 'كلمة السر الحالية',
      current_wallet: 'المحفظة الحالية',
      dashboard: 'لوحة التحكم',
      data_added: 'البيانات المضافة',
      data_saved: 'البيانات المحفوظة',
      datas: 'بيانات',
      date: 'تاريخ',
      date_first_installment: 'تاريخ الدفعة الأولى',
      ddi: 'الرقم الدولي',
      deb_de_trader: 'ديون التاجر',
      deb_game_donate: 'لعبة التبرع بالخصم',
      deb_saldo: 'رصيد مدين',
      deb_saldo_disp: 'الرصيد المتاح للخصم',
      deb_saldo_pendent: 'الرصيد المدين المستحق',
      deb_val_indev: 'خصم المبلغ غير المستحق',
      debit_card_payment: 'الدفع عن طريق بطاقة الخصم',
      debit_card_payment_return: 'إعادة الدفع عن طريق بطاقة الخصم',
      debit_card_receipt: 'الاستلام عن طريق بطاقة الخصم',
      debt: 'دَين',
      debt_td: 'دَين',
      December: 'ديسمبر',
      decImpRenda: 'أعلن ضريبة الدخل',
      deleted_document: 'تم حذف المستند من السجل الخاص بك',
      department: 'قسم',
      dependents: 'المعالين',
      desc: 'إلى الأسفل',
      description: 'وصف',
      details: 'تفاصيل',
      dev_econom: 'النمو الإقتصادي',
      developer: 'مطور',
      directly_indicated: 'وأشار مباشرة',
      directly_indicateds: 'تمت الإشارة إليه مباشرة',
      directs: 'مباشر',
      district: 'الحي / المنطقة',
      divorced: 'مُطلّق',
      doa_men_receb: 'تم استلام التبرع الشهري للمنصة',
      doacao_td: 'هبة',
      doctorate_complete: 'دكتوراه - كاملة',
      doctorate_incomplete: 'دكتوراه - غير مكتملة',
      document: 'وثيقة',
      document_already_registered: 'الوثيقة مسجلة بالفعل',
      documents: 'وثائق',
      donate: 'يتبرع',
      donor: 'جهات مانحة',
      dont_know_zip: 'لا تعرف الرمز البريدي؟ انقر هنا',
      dou_fe: 'أؤكد أن المعلومات الواردة أعلاه صحيحة.',
      downloads: 'تحميل ملفات',
      drop_images: 'انقر أو اسحب الصور هنا',
      due_date: 'نضج',
      edit: 'للتعديل',
      edit_option: 'خيار التحرير',
      edit_question: 'تحرير السؤال',
      email: 'بريد إلكتروني',
      email_already_registered: 'البريد الإلكتروني مسجل مسبقا',
      email_and_or_password_wrong: 'البريد الإلكتروني و/أو كلمة المرور خاطئة!',
      email_for_login: 'سيكون هذا البريد الإلكتروني بمثابة تسجيل الدخول الخاص بك',
      email_or_password_wrong: 'بريد أو كلمة مرورغير صحيحة',
      employee: 'موظف',
      employees: 'موظفين',
      empty: 'فارغ',
      enter_your_new_password: 'أدخل كلمة المرور الجديدة',
      entrada: 'هل لها قيمة دخول؟ إذا كانت الإجابة بنعم، ما هي القيمة',
      entries_until_today: 'الإدخالات حتى الآن',
      error_confirm_password: 'يجب أن يكون التأكيد وكلمة المرور الجديدة متماثلين',
      error_email: 'تنسيق البريد الإلكتروني غير صالح',
      error_password: 'كلمة سر خاطئة',
      error_phone: 'رقم الهاتف غير صحيح',
      estadoInteresse: 'ولاية',
      estimate: 'مُقدَّر',
      exchange: 'إستبدال',
      expired: 'منتهي الصلاحية',
      extorno_td: 'انعكاس، ارتداد، انقلاب',
      extra: 'إضافي',
      extract: 'يستخرج',
      extracts: 'مقتطفات',
      fantasy: 'خيالي',
      fatherName: 'اسم الاب',
      February: 'شهر فبراير',
      fee: 'رسوم الانسحاب',
      feedback: 'يعود',
      feminine: 'المؤنث',
      fgts: 'قيمة FGTS الخاصة بك',
      field_required: 'الانتهاء الإلزامي',
      final: 'أخير',
      finance: 'مالي',
      financial: 'مالي',
      financial_payment: 'الدفع المالي',
      financial_payment_return: 'عودة الدفع المالي',
      financial_receipt: 'استلام التمويل',
      financing: 'التمويل',
      financings: 'التمويل',
      first_10_pages: 'أول 10 صفحات',
      fisica: 'شخص مادي',
      for_year: 'بالسنة',
      forget_password: 'لقد نسيت كلمة المرور',
      forgot_password: 'هل نسيت كلمة السر',
      form: 'استمارة',
      form_of_payment: 'طريقة الدفع',
      found: 'وجد',
      founds: 'وجد',
      free: 'حر',
      friend_indicated: 'أوصت به أصدقائي',
      friend_indicateds: 'أوصت به أصدقائي',
      fuel: 'وقود',
      full_name: 'الاسم الكامل',
      funcPublico: 'هو موظف عام',
      fundamental_complete: 'أساسي - كامل',
      fundamental_incomplete: 'الأساسية - غير مكتملة',
      ganh_diario: 'الأرباح اليومية',
      gender: 'جنس',
      grand_total: 'المجموع الإجمالي',
      group: 'مجموعة',
      has_withdraw_today: 'وقد تم بالفعل طلب الانسحاب اليوم',
      high: 'عالي',
      home: 'يبدأ',
      how_create_binance_apikey: 'كيفية إنشاء مفتاح API على Binance',
      how_it_works: 'كيف تعمل',
      how_to_call: 'ماذا تريد أن تسمى؟',
      id_td: 'الإحالة المباشرة',
      images: 'الصور',
      in_construction: 'تحت التشيد',
      in_order: 'مرتب',
      in_stock: 'في الأوراق المالية',
      indicant: 'مؤشر',
      indicated_by: 'تتم إحالتك من قبل',
      indicated_not_found: 'لم يتم العثور على المرجع المشار إليه...',
      indicateds: 'المرشحين',
      indirects: 'غير مباشر',
      info: 'النشرات الإخبارية',
      info_contact: 'معلومات الاتصال',
      initial: 'بيت',
      insert_new: 'أدخل جديد',
      installments: 'أقساط',
      intelligence: 'ذكاء',
      interesse: 'ما هي المنطقة ذات الاهتمام',
      invalid_contact: 'تنسيق جهة الاتصال غير صالح',
      invalid_images: 'صور غير صالحة',
      invalid_token: 'رمز غير صالح',
      invite: 'لدعوة',
      invite_friends: 'اعزم أصحابك',
      January: 'يناير',
      July: 'يوليو',
      June: 'يونيو',
      juridica: 'شخص قانوني',
      know_exact_result: 'هل تعرف القيمة الدقيقة؟',
      lead: 'معًا سوف نبني مستقبلًا جديدًا.',
      learn_more: 'تعرف أكثر',
      left: 'غادر',
      left_preferential: 'تفضيل الجانب الأيسر',
      legalNature: 'الطبيعة القانونية',
      level: 'مستوى',
      limite_80: 'لقد وصلت إلى {{perc}} من الحد المسموح به.\n قم بالتحسين وتجنب تعليق دخلك.',
      limite_excedido: 'تم تجاوز حد الخصم',
      linear: 'خطي',
      link_email: 'انقر على الرابط المرسل إلى البريد الإلكتروني المسجل!',
      link_invite_copy: 'تم نسخ رابط المشاركة بنجاح',
      liquid: 'سائل',
      list: 'قائمة',
      loading: 'تحميل',
      location: 'موقع',
      locked: 'محظور',
      login: 'للدخول',
      logout: 'للخروج',
      low: 'قليل',
      male: 'مذكر',
      manage_users: 'ادارة المستخدمين',
      manager: 'مدير',
      mandala: 'ماندالا',
      mandalas: 'المندالا',
      manufacturer: 'الصانع',
      March: 'يمشي',
      maritalStatus: 'الحالة الاجتماعية',
      market: 'سوق',
      married: 'متزوج',
      master_complete: 'درجة الماجستير - كاملة',
      master_incomplete: 'درجة الماجستير - غير مكتملة',
      max_payment: 'الحد الأقصى للدفع',
      May: 'يمكن',
      message: 'رسالة',
      messageNotSent: 'لم يكن من الممكن إرسال رسالتك',
      messageSent: 'تم ارسال رسالتك بنجاح',
      middle_complete: 'متوسطة - كاملة',
      middle_incomplete: 'متوسطة - غير مكتملة',
      min_amount_error: 'يجب أن تكون القيمة على الأقل:',
      min_withdraw: 'الحد الأدنى لمبلغ السحب',
      missing: 'مفتقد',
      mission: 'مهمة',
      mission_vision_values: 'الرسالة والرؤية والقيم',
      model: 'نموذج',
      monitors: 'الشاشات',
      monthly: 'الدفع الشهري',
      motherName: 'اسم الأم',
      movements: 'الحركات',
      msg_renovacao: 'لقد وصلت إلى حد الربح الخاص بك، قم بتجديد باقتك.',
      my_account: 'حسابي',
      my_wallet: 'محفظتى',
      mystock: 'مخزوني',
      name: 'اسم',
      nascMaisVelhoRenda2: 'ما هو تاريخ ميلاد أكبرهم؟',
      nationality: 'الجنسية (بلد الميلاد)',
      naturalness: 'مكان الميلاد (مدينة الميلاد)',
      nature: 'طبيعة',
      network: 'شبكة',
      new: 'جديد',
      new_call: 'مكالمة جديدة',
      new_financing: 'التمويل الجديد',
      new_order: 'طلب جديد',
      new_password: 'كلمة المرور الجديدة',
      new_password_security: 'كلمة مرور أمنية جديدة',
      new_seller: 'بائع جديد',
      new_wallet: 'محفظة جديدة',
      news: 'أخبار',
      next: 'التالي',
      nick: 'المستخدم (تسجيل الدخول)',
      nis: 'PIS/شيكل',
      nis_already_registered: 'PIS/NIS مسجل بالفعل',
      no: 'لا',
      no_approve: 'لم تتم الموافقة عليه بعد',
      no_delete: 'لا تحذف',
      no_literate: 'غير متعلم',
      no_repeat: 'لا يمكن تكرار البيانات',
      no_results_found: 'لم يتم العثور على نتائج',
      no_services_or_products_added: 'لم تتم إضافة أي خدمات أو منتجات',
      no_settings: 'انتقل إلى الإعدادات واملأ التفاصيل الخاصة بك.',
      none_friends_indicated: 'لم أقم بإحالة أي أصدقاء بعد',
      not_authorized: 'غير مخول',
      not_found: 'غير معثور عليه',
      not_login: 'غير قادر على تسجيل الدخول، يرجى التحقق من التفاصيل الخاصة بك وحاول مرة أخرى!',
      not_orders: 'لا توجد أوامر الدفع',
      not_permission: 'ليس لديك الصلاحية لدخول هذه الصفحة',
      not_registered: 'لم يتم تسجيلة بعد؟ انقر هنا وقم بالتسجيل',
      not_results: 'لا توجد سجلات',
      not_self_register: 'غير قادر على التسجيل',
      not_today_entry: 'مازلنا لم نحصل على التذاكر اليوم',
      notfound_ad: 'لم يتم العثور على إعلانات',
      November: 'شهر نوفمبر',
      number: 'رقم',
      obs: 'تعليقات',
      obs_pay:
        'إذا قام الوسيط الخاص بك بخصم رسوم من المبلغ المرسل، فيجب عليك إجراء التصحيح يدويًا عن طريق إضافة مبالغ هذه الرسوم.',
      October: 'اكتوبر',
      of: 'في',
      off: 'عن',
      on_total: 'من المجموع الكلي',
      open: 'يفتح',
      opening: 'افتتاح',
      operation: 'أداء',
      ordenation: 'الطلب',
      order: 'طلب',
      order_book: 'سجل الطلبيات',
      order_not_alowed_to_pay: 'طلب غير مصرح به للدفع بالرصيد',
      order_not_found: 'لم يتم العثور على الطلب',
      order_payed: 'تم دفع الطلب رقم {{id}} بنجاح',
      orders: 'الطلبات',
      other: 'آخر',
      other_services_or_products: 'خدمات أو منتجات أخرى',
      paid: 'دفع',
      paid_date: 'يوم الدفع',
      partial: 'المدفوعة جزئيا',
      passport: 'جواز سفر',
      password: 'كلمة المرور',
      password_security: 'كلمة المرور الأمن',
      pay: 'يدفع',
      pay_exact: 'ادفع المبلغ بعملة {{coin}}',
      pay_generate: 'توليد الدفع',
      pay_selected: 'الدفع المحدد',
      pay_value_in: 'دفع المبلغ في',
      pay_with_balance: 'الدفع بالرصيد',
      payable: 'يدفع',
      payer: 'دافع',
      payment: 'قسط',
      ped_retirada: 'طلب سحب',
      ped_retirada_gan_diario: 'طلب سحب الأرباح اليومية',
      pendent: 'قيد الانتظار',
      pendent_order: 'لديك أوامر معلقة',
      people: 'الناس',
      person: 'شخص',
      person_found_in_other_prefecture: 'شخص مسجل بالفعل في بلدية أخرى',
      personal: 'شباب',
      personal_addresses: 'العناوين',
      personal_contacts: 'جهات الاتصال',
      personal_data: 'بيانات شخصية',
      personal_infos: 'معلومة',
      personal_pcva: 'برنامج البيت الأخضر والأصفر',
      phone: 'رقم هاتف',
      photo: 'تصوير',
      pix: 'بيكس',
      pix_payment: 'الدفع بالبيكس',
      pix_payment_return: 'إعادة الدفع في PIX',
      pix_receipt: 'الاستلام في PIX',
      pl_carreira: 'المسار المهني',
      plan: 'مستوي',
      plates: 'لوحات',
      please_wait: 'انتظر من فضلك',
      plus: 'زائد',
      point: 'نقطة',
      points: 'نقاط',
      portfolio: 'مَلَفّ',
      pos_complete: 'بالمعنى اللاتيني - كامل',
      pos_incomplete: 'بالمعنى اللاتيني - غير مكتمل',
      prefecture: 'بلدية',
      prefecture_addresses: 'العناوين',
      prefecture_contacts: 'جهات الاتصال',
      prefecture_data: 'بيانات قاعة المدينة',
      prefecture_infos: 'معلومة',
      prefectures: 'قاعات المدينة',
      prev: 'سابق',
      price: 'سعر',
      print: 'اطبع',
      privacy_policies: 'سياسات الخصوصية',
      private_works: 'أعمال خاصة',
      profession: 'مهنة',
      profile: 'حساب تعريفي',
      program: 'برنامج',
      programs: 'برمجة',
      project: 'مشروع',
      projects: 'المشاريع',
      public_works: 'الأشغال العامة',
      purchase: 'شراء',
      qualify_binary: 'مؤهل النقاط المزدوجة',
      qualquer: 'أي',
      quantity: 'كمية',
      quotation: 'سعر',
      read: 'ليقرأ',
      receivable: 'لاستقبال',
      recipient: 'المتلقي',
      recommendations: 'التوصيات',
      redefine_password: 'إعادة تعريف كلمة المرور',
      register: 'سِجِلّ',
      register_people: 'تسجيل الأشخاص',
      register_wallet: 'قم بتسجيل محفظة لإجراء عمليات السحب',
      registers: 'السجلات',
      registers_users: 'تسجيلات المستخدم',
      reject: 'يرفض',
      remaining: 'متبقي',
      remove: 'لازالة',
      rendaIndividual: 'الدخل الإجمالي الفردي',
      renew: 'تجديد',
      renew_mandala: 'تجديد ماندالا',
      reply: 'ليجيب',
      reports: 'التقارير',
      required_data_not_sent: 'لم يتم إرسال البيانات المطلوبة',
      resend_email: 'إعادة إرسال البريد الإلكتروني',
      residential: 'سكني',
      responded: 'أجاب',
      restricao: 'هل لديك قيود على اسمك؟ (توافق آراء ساو باولو/سيراسا)',
      result: 'نتيجة',
      results: 'نتائج',
      resume_data: 'ملخص البيانات',
      revoked: 'ألغيت',
      rg: 'التسجيل العام (الهوية)',
      rgAgency: 'مصدر RG',
      rgDate: 'تاريخ الإصدار RG',
      rgUf: 'دولة آر جي',
      right: 'يمين',
      right_preferential: 'تفضيل الجانب الأيمن',
      roi: 'الأرباح اليومية',
      sale: 'أُوكَازيُون',
      saq_duplicado: 'انسحاب مزدوج',
      save: 'للحفظ',
      schooling: 'تعليم',
      search: 'يبحث',
      secretary: 'سكرتير',
      security_password: 'كلمة المرور الأمن',
      see: 'لترى',
      see_advents: 'شاهد الإعلانات',
      see_call: 'عرض المكالمة',
      see_details: 'انظر التفاصيل',
      see_details_wallet: 'عرض تفاصيل المحفظة',
      see_financing: 'عرض التمويل',
      see_order: 'مشاهدة الطلب',
      see_user: 'عرض المستخدمين',
      select_vehicle: 'اختر مركبة',
      self_register: 'يسجل',
      sell_volume: 'حجم المبيعات',
      seller: 'تاجر',
      send_call: 'إرسال مكالمة',
      send_to: 'ارسل إلى',
      September: 'سبتمبر',
      settings: 'إعدادات',
      share_your_link: 'مشاركة الرابط الخاص بك',
      show: 'عرض حتى',
      show_from: 'العرض من',
      side: 'جانب',
      sign_in_to_start_your_session: 'قم بتسجيل الدخول لبدء جلستك',
      sign_up: 'يسجل',
      single: 'أعزب',
      sold: 'مُباع',
      state: 'ولاية',
      status: 'حالة',
      strategies: 'الاستراتيجيات',
      street: 'طريق',
      subject: 'موضوع',
      submit: 'لترسل',
      submit_placeholder: 'يرجى ملء البريد الإلكتروني الخاص بك',
      subscribe: 'قم بالتسجيل لتلقي أخبارنا',
      subtitle: 'التسمية التوضيحية',
      success_update_user: 'تم تحديث المستخدم بنجاح!',
      success_wallet: 'تم تحديث المحفظة بنجاح!',
      suggestions: 'اقتراحات',
      superior_complete: 'تخرج',
      superior_incomplete: 'غير مكتمل أعلى',
      supervisor: 'مشرف',
      support: 'يدعم',
      support_materials: 'مواد الدعم',
      suspended: 'معلق',
      symbol: 'رمز',
      symbols: 'حرف او رمز',
      system: 'نظام',
      target: 'هدف',
      telegram_chat_id: 'معرف دردشة برقية',
      tempoCarteira: 'منذ متى وأنت مسجل في محفظتك؟',
      terms: 'أقبل شروط الاستخدام',
      text_hint_binance:
        'عند إنشاء مفتاح واجهة برمجة التطبيقات على Binance، أدخل عنوان IP في قيود الوصول:\n172.105.78.139\nثم حدد الخيارات:\n تمكين الخيارات الأوروبية\n تمكين التداول الفوري والهامش',
      this_value_in: 'هذه القيمة في',
      ticker_health: 'تيكر هيلث',
      title: 'عنوان',
      to: 'حتى',
      to_pay: 'لإيقاف',
      to_receive: 'مستحق',
      today_entry: 'دخول اليوم',
      token_not_found_or_already_used: 'لم يتم العثور على الرمز المميز أو تم استخدامه بالفعل',
      total: 'المجموع',
      total_entry: 'إجمالي الدخول',
      traderbot: 'تاجر بوت',
      transf_credito: 'رسوم التحويل',
      transf_debito: 'تحويل الخصم',
      transfer_payment: 'الدفع عن طريق التحويل',
      transfer_payment_return: 'إعادة الدفع عن طريق التحويل',
      transfer_receipt: 'الاستلام في التحويل',
      transparency: 'الشفافية',
      tx_adm_cred: 'إدارة الضرائب',
      tx_adm_deb: 'إدارة الضرائب',
      tx_retirada: 'رسوم الانسحاب',
      tx_retirada_gan_diario: 'رسوم سحب الأرباح اليومية',
      type: 'يكتب',
      update: 'للتحديث',
      update_wallet: 'تحديث المحفظة',
      upgrade: 'تحسين',
      used: 'مستخدم',
      user: 'مستخدم',
      user_active: 'مستخدم نشط',
      user_already_exists: 'المستخدم موجود اصلا!',
      user_and_or_password_wrong: 'اسم المستخدم و / أو كلمة المرور خاطئة!',
      user_inactive: 'مستخدم غير نشط',
      user_not_found: 'لم يتم العثور على المستخدم',
      user_not_logged: 'لم يقوم المستخدم بتسجيل الدخول',
      user_or_password_wrong: 'اسم المستخدم أو كلمة المرور غير صحيحة',
      users: 'المستخدمين',
      users_list: 'قائمة المستخدم',
      valid_images: 'صور صالحة',
      valorRenda2: 'ما هي قيمة الدخل الثاني',
      value: 'قيمة',
      value_donate_on: 'قيمة التبرع في',
      value_financed: 'المبلغ الممول',
      value_installment: 'قيمة القسط',
      values: 'قيم',
      vehicle: 'عربة',
      vehicles: 'مركبات',
      view_order: 'مشاهدة الطلب',
      vision: 'رؤية',
      visitor: 'زائر',
      voucher: 'فاتورة',
      wait_confirm: 'في انتظار التأكيد',
      waiting: 'منتظر',
      waiting_email: 'تم التسجيل بنجاح، الآن كل ما عليك فعله هو تأكيد بريدك الإلكتروني.',
      waiting_payment: 'انتظار الدفع',
      wallet: 'مَلَفّ',
      wallet_generate: 'إنشاء المحفظة',
      wallets: 'محافظ',
      want_donate: 'أريد التبرع',
      want_now_more_about: 'أريد أن أعرف المزيد عنها',
      warning: 'انتباه',
      we_are: 'نكون',
      webhooks: 'خطافات الويب',
      welcome: 'مرحباً',
      who_we_are: 'من نحن',
      who_we_are_subtitle: 'وصف موجز لل',
      widower: 'أرمل',
      will_expire_in: 'سوف تنتهي في',
      with: 'مع',
      withdraw: 'انسحاب',
      withdraw_sended_email: 'تم طلب السحب، تحقق من بريدك الإلكتروني وانقر على الرابط للتفويض!',
      withdrawal: 'عمليات السحب',
      withdrawals: 'عمليات السحب',
      year: 'سنة',
      years: 'سنين',
      yes: 'نعم',
      you_have: 'لديك',
      your_country: 'بلدك',
      your_dashboard: 'هذه هي لوحة التحكم الخاصة بك.',
      your_link: 'وصلتك',
      zipcode: 'الرمز البريدي',
      withdrawal_sended: 'تم إرسال السحب',
      withdrawal_pending: 'تم طلب السحب بالفعل! \nيرجى انتظار الدفع لإجراء عملية سحب أخرى.',
      limite_80_top: 'لقد وصلت إلى {{perc}} الحد المسموح به.\n توقع التجديد وتجنب تعليق دخلك.',
      game: 'لعبة',
      games: 'ألعاب',
      winning_numbers: 'الأرقام المرسومة',
      your_numbers: 'أرقامك',
      add_numbers: 'إضافة أرقام',
      next_draw: 'السحب القادم',
      can_bet_until: 'يمكنك المراهنة على ما يصل إلى',
      yout_bets: 'المراهنات الخاصة بك',
      add_bet: 'أضف الرهان',
      bet: 'رهان',
      bets: 'الرهانات',
      numbers: 'أعداد',
      special_numbers: 'أرقام مميزة',
      resume: 'ملخص',
      choosed_numbers: 'أرقام مختارة',
      choosed_special_numbers: 'تم اختيار أرقام مميزة',
      bets_finished: 'الرهانات المستقرة',
      prize_draw: 'جائزة السحب',
      balance_network: 'توازن الشبكة',
      telegram: 'برقية',
      logs: 'السجلات',
      release_withdrawals: 'الافراج عن الانسحابات',
      bot_active: 'الافراج عن بوت',
      available: 'متاح',
      verify: 'للتأكد',
      rescue: 'ينقذ',
      awarded: 'منحت',
      rescued: 'تم إنقاذه',
      repeat: 'يكرر',
      draw_date: 'تاريخ السحب',
      bet_date: 'تاريخ الرهان',
      drawn_numbers: 'الأرقام المرسومة',
      awardeds_numbers: 'أرقام الجائزة',
      no_award: 'لا جائزة',
      you_hit_number: 'لقد حصلت على رقم {{qty}} بشكل صحيح',
      you_hit_numbers: 'لقد حصلت على {{qty}} أرقام صحيحة',
      lottery: 'اليانصيب',
      lotteries: 'اليانصيب',
      balls: 'كرات',
      special_balls: 'العروض الخاصة',
      levy: 'مجموعة',
      major_awarded: 'المزيد من الكرات',
      hits: 'الزيارات',
      draw_due: 'الحد الأقصى للرهان',
      close_game: 'لعبة قريبة',
      draw_due_greater_than_draw_date: 'يجب أن يكون حد الرهان على الأقل {{timeLimit}} قبل السحب',
      draw_date_less_than_now: 'يجب أن يكون تاريخ السحب أكبر من التاريخ الحالي بما لا يقل عن {{timeLimit}} دقيقة',
      balance_game: 'التوازن للألعاب',
      your_balance: 'رصيدك',
      your_balance_total: 'رصيدك الإجمالي',
      game_package: '{{qty}} عدد (يمكن الفوز بـ {{prize}} مرة)',
      choose_package: 'اختر الحزمة',
      error_bet: 'غير قادر على إرسال الرهان',
      do_complete: 'لإكمال',
      success_bet: 'تم إرسال الرهان',
      you_bet_number: 'أنت تراهن بأرقام {{qty}}.',
      winner_msg: 'وفاز بـ {{prize}} مرة',
      prize: 'جائزة',
      balance_not_enough: 'رصيد غير كاف',
      addbalance: 'إضافة رصيد إلى الألعاب',
      convertbalance: 'تحويل الأرصدة',
      convertbalance_title: 'تحويل الرصيد المتاح إلى رصيد اللعبة',
      can_award: 'يمكنك الفوز',
      emission: 'انبعاث',
      validation: 'تصديق',
      participant: 'مشارك',
      concourse: 'منافسة',
      draw_hours: 'رسم الوقت',
      qty_dezenas: 'كمية العشرات',
      bet_amount: 'مبلغ الرهان',
      share: 'للمشاركه',
      shared: 'جاهز للمشاركة',
      simulation: 'محاكاة',
      valuePay: 'المبلغ المطلوب دفعه',
      quotas_paid_success: 'الدفع الناجح',
      paids_today: 'المدفوعات التي تمت اليوم',
      no_paids_today: 'لم يتم سداد أي مدفوعات اليوم',
      perc: 'نسبة مئوية',
      define_percent: 'تعيين النسبة المئوية',
      success_update: 'تم التحديث بنجاح',
      define_percent_next_payment: 'تعيين النسبة المئوية للدفع التالي',
      better: 'مستشار الرهان',
      lottery_consultant: 'مستشار مشارك',
      create: 'إنشاء',
      doclose: 'غلق',
      lottery_updated: 'لعبة تم تحديثها',
      lottery_created: 'لعبة تم انشاءها',
      award_packages: 'المكافآت',
      qty: 'كمية',
      multiplier: 'مضاعف',
      maxValue: 'القيمة الأعلى',
      limitRepeat: 'الحد الأقصى للتكرار',
      draw_day: 'يوم السحب',
      day: 'يوم',
      hour: 'ساعة',
      minutes: 'دقيقة',
      ytb_channel_id: 'معرف قناة YouTube',
      tutorial: 'درس',
      video_url: 'رابط الفيديو',
      tutorial_created: 'تم إنشاء البرنامج التعليمي',
      tutorial_deleted: 'تم حذف الدرس',
      tutorial_error_delete: 'خطأ في حذف الدرس',
      tutorial_updated: 'تم تحديث الدرس',
      tutorial_error_update: 'خطأ في تحديث الدرس',
      english: 'الإنجليزية',
      spanish: 'الأسبانية',
      portuguese: 'البرتغالية',
      select_language: 'اختر اللغة',
      language: 'لغة',
      add_balance_games: 'إضافة رصيد إلى الألعاب',
      consultant_indication: 'تعيين استشاري',
      mensal_activation: 'تفعيل الشهري',
      your_bets: 'الرهانات الخاصة بك',
      sunday: 'الأحد',
      monday: 'ثانية',
      tuesday: 'ثالث',
      wednesday: 'الرابع',
      thursday: 'الخامس',
      friday: 'جمعة',
      saturday: 'السبت',
      title_binary: 'قم بتعيين نسب النقاط المزدوجة لكل يوم',
      ok_perc_change: 'تم تغيير النسب بنجاح',
      cred_deb_title: 'الرصيد الائتماني أو المدين',
      send: 'لترسل',
      voucher_pendent: 'قسيمة معلقة',
      leader_pendent: 'الزعيم المنتظر',
      voucher_approved: 'تمت الموافقة على القسيمة',
      leader_approved: 'القائد المعتمد',
      no_binary_at_today: 'لم يتم تسجيل Binary في ذلك اليوم',
      generate_file: 'إنشاء ملف',
      withdrawals_file_created: 'تم إنشاء ملف السحب',
      withdrawals_sended: 'تم إرسال طلبات السحب',
      see_generated_files: 'عرض الملفات التي تم إنشاؤها',
      files: 'ملفات',
      file: 'ملف',
      conversion: 'التحويل بين الأرصدة',
      betting_commission: 'لجنة الرهان',
      be_a_consultant: 'كن مستشارًا لليانصيب واكسب المزيد',
      msg_be_a_consultant:
        'قسم الربح العالمي في Lotter.\nمكافأة الإحالة (على مبيعات الحزمة)\nمكافأة النقاط المزدوجة\nوأكثر من ذلك بكثير.',
      auto_renovations: 'التجديدات التلقائية',
      auto_renove_binary: 'التجديد الذاتي للنقطة المزدوجة',
      auto_renove_quotas: 'التجديد الذاتي للأهداف (220%)',
      auto_renove_anual: 'التجديد الذاتي السنوي',
      on: 'متصل',
      change_success: 'تغيرت بنجاح',
      anual: 'سنوي',
      only_monday: 'ستكون عمليات السحب متاحة كل يوم اثنين',
      transferency: 'تحويل',
      max_amount: 'القيمة القصوى',
      info_better:
        '- احصل على 100% من حزمة الدخول الخاصة بك والتجديدات في الاعتمادات للمراهنة.\n\n- يمكنك إضافة أرصدة مراهنة جديدة وقتما تشاء.\n\n- شارك في كل خطة عمل، ويمكنك الربح من الإحالات المباشرة وغير المباشرة ومكافأة النقاط المزدوجة والمستوى الأحادي والخطة الوظيفية والجوائز.',
      info_consultant:
        '- لا يتلقى أرصدة للرهانات، يبيع المستشار المؤسس أرصدته للرهانات من حزمة الدخول الخاصة به والتجديدات ليقوم The Lotter بالتفاوض عليها، وبالتالي يتلقى ما يصل إلى 1% في أيام الأسبوع من قيمة مجموعته حتى تصل إلى 220% و يمكنك بعد ذلك التجديد إذا كنت ترغب في ذلك.\n\n- يمكنك زيادة رصيد الرهان الخاص بك وقتما تشاء.\n\n- المشاركة في كل خطة عمل، والقدرة على الربح من الإحالات المباشرة وغير المباشرة، ومكافأة النقاط المزدوجة، وأحادي المستوى، الخطة المهنية والجوائز.',
      confirm: 'يتأكد',
      send_new_token: 'بمجرد إرسال التأكيد إلى بريدك الإلكتروني، انقر على بريدك الإلكتروني للتأكيد',
      email_resended: 'تم إرسال التأكيد إلى بريدك الإلكتروني، اضغط على بريدك الإلكتروني للتأكيد',
      expired_send_new_token: 'تم إرسال تأكيد جديد إلى بريدك الإلكتروني، اضغط على بريدك الإلكتروني للتأكيد',
      become_a_consultant: 'قم بالترقية واحصل على المزيد',
      register_ok: 'مسجل بنجاح',
      ticket_number: 'رقم التذكرة',
      conversion_to_bet: 'التحويل إلى الرهان',
      conversion_balance_games: 'تحويل الرصيد إلى رصيد اللعبة',
      conversion_balance_lotterprocoin: 'تحويل الرصيد إلى التنشيط',
      conversion_lotterprocoin_games: 'التنشيط لتحويل رصيد اللعبة',
      conta_corrente: 'الحساب الحالي',
      balance_games: 'توازن اللعبة',
      transf_debit_lotterProCoin: 'تحويل رصيد التنشيط',
      deb_saldo_lotterProCoin: 'الخصم من رصيد التنشيط',
      transf_credit_lotterProCoin: 'تحويل الرصيد إلى التنشيط',
      live_now: 'البث المباشر الآن',
      offline: 'غير متصل: في انتظار الرسم',
      tutorials: 'البرامج التعليمية',
      msg_renove_binary:
        'لقد أكملت بالفعل شهرًا آخر من آخر تنشيط شهري وليس لديك تنشيط شهري تلقائي.\nولهذا السبب أصبحت نقاطك المزدوجة غير نشطة.\nادفع طلبًا لإضافة رصيد اللعبة بمبلغ 25.00 دولارًا لتنفيذ التنشيط الشهري .',
      deb_balance_games: 'الخصم من رصيد اللعبة',
      cred_saldo_lotterProCoin: 'الدفع لرصيد التنشيط',
      live_hour: 'البث المباشر في الساعة',
      choose_side_preferential: 'اختر الجانب المفضل (حاليًا: {{side}})',
      count: 'العدد',
      historics: 'التاريخ',
      balance_lotterProCoin: 'توازن التنشيط',
      msg_binary_tree_1: 'اضغط على المستخدم المطلوب لمدة ثانية واحدة لرؤية المعلومات',
      msg_binary_tree_2: 'انقر على المستخدم المطلوب مرة واحدة لرؤية المستخدم التالي في الملف الثنائي.',
      change_security_password: 'تغيير كلمة المرور الأمنية',
      info_pass_security: 'يتم استخدام كلمة المرور الأمنية لتنفيذ المعاملات المالية',
      redefine_security_password: 'تعيين كلمة مرور جديدة',
      redefined_password: 'تم إعادة تعيين كلمة المرور بنجاح!',
      click_to_enter: 'انقر للدخول',
      title_banner: 'مرحبًا بك في {{company}}، {{name}}!',
      descryption_banner: 'انسخ رابط التسجيل أدناه وشاركه مع أصدقائك لتعظيم أرباحك',
      no_binaries: 'لا يمكن عرض شبكة هذا المستخدم',
      invalid_wallet: 'المحفظة غير صالحة',
      valid_wallet: 'ترخيص ساري المفعول',
      wallet_usdc: 'محفظة USDC',
      comunicado_20240705:
        'يعلن فريق Aibot بكل فخر أنه بعد بدء بيع التراخيص، أصبح الذكاء الاصطناعي لدينا جاهزًا لتنفيذ العمليات. \n نطلب من الجميع تحديث بياناتهم وإرسال وثائقهم لبدء رحلة رائعة. \n\nيبدأ كل شيء بعد 24 ساعة من إرسال المستندات والموافقة عليها لكل حساب. \n\n اغتنم الفرصة لشراء تراخيصك لأنها محدودة، واضمن القيمة المضاعفة داخل وسيط Binance أو Coinbase الخاص بك',
      customer: 'عميل',
			estorno_comission: 'عكس اللجنة',
    },
  },
}
export { messages }
