import axios from 'axios'
import { encrypt } from '../utils/crypto'

axios.interceptors.request.use(
  config => {
    config.headers.Authorization = sessionStorage.getItem('token')
    config.headers.Params = !sessionStorage.getItem('token')
      ? ''
      : encrypt(sessionStorage.getItem('token').split('.').slice(-1)[0])
    return config
  },
  error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      console.error('Redirected to login by 401 response!')
      window.location = '/logout'
    } else return Promise.reject(error)
  }
)

export default axios
