import React, { useRef } from 'react'
import { i18n } from '../../../utils/translates/i18n'

function ChargebackModal({ chargeBack, confirmeChargeback, cancelChargeback }) {
	const btnClose = useRef('')
	
	const doConfirmeChargeback = () => {
		confirmeChargeback()
		btnClose.current.click();
	}
	
	const doCancelChargeback = () => {
		cancelChargeback()
		btnClose.current.click();
	}

  return (
    <div
      className="modal fade"
      id="modalChargeback"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleNotify"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleNotify">
              {`${i18n.t('extorno_td')} ${i18n.t('withdrawal')} # ${chargeBack}`}
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"></button>
          </div>
          <div className="modal-body">
            <div className="btn-group w-100">
              <button className="btn btn-danger btn-lg" onClick={doConfirmeChargeback}>
                {i18n.t('extorno_td')}
              </button>
              <button className="btn btn-secondary btn-lg" onClick={doCancelChargeback}>
                {i18n.t('after')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChargebackModal
