import React, { useRef } from 'react';
import { i18n } from '../../../utils/translates/i18n';
import { FormateFiatCurrency } from '../../../utils/CustomFormatations';

/**
 * props:
 * - id
 * - onSubmit
 */
function WithdrawModal({ withdraw, payWithdraw, totalSelected }) {
	const btnClose = useRef('');

	const doPayWithdraw = () => {
		payWithdraw();
		btnClose.current.click();
	};

	return (
		<div
			className='modal fade'
			id='modalWithdraw'
			tabIndex='-1'
			role='dialog'
			aria-labelledby='modalTitleNotify'
			aria-hidden='true'>
			<div className='modal-dialog modal-dialog-centered' role='document'>
				<div className='modal-content'>
					<div className='modal-header'>
						<p className='modal-title' id='modalTitleNotify'>
							{`${i18n.t('pay')} ${i18n.t('withdrawals')}`}
						</p>
						<button
							ref={btnClose}
							type='button'
							className='btn-close'
							data-bs-dismiss='modal'
							aria-label='close'></button>
					</div>
					<div className='modal-body'>
						<div className='alert alert-light'>
							{i18n.t('quantity')}: {withdraw.length}
							<br />
							{i18n.t('total')}: {FormateFiatCurrency(totalSelected)}
						</div>
						<div className='alert alert-light'>
							{i18n.t('withdrawals')} IDs: {withdraw.join(', ')}
						</div>
						<div className='btn-group w-100'>
							<button className='btn btn-primary btn-lg' onClick={doPayWithdraw}>
								{i18n.t('pay')}
							</button>
							{/* <button className='btn btn-secondary btn-lg' onClick={() => doPayWithdraw(withdraw, 'voucher')}>
								{i18n.t('voucher')}
							</button> */}
							<button className='btn btn-secondary btn-lg' onClick={() => btnClose.current.click()}>
								{i18n.t('after')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default WithdrawModal;
