import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}`

export async function getMovimentTypes() {
  const endPoint = `${ENDPOINT}/moviments/types`
  const response = await axios.get(endPoint)
  return response.data
}

export async function sendCredDebt(token, data) {
  const endPoint = `${ENDPOINT}/creddebt`

  const response = await axios.post(endPoint, data)
  return response.data
}

export async function sendProlabore(data) {
  const endPoint = `${ENDPOINT}/prolabore`

  const response = await axios.post(endPoint, data)
  return response.data
}
