const messages = {
  fr: {
    translations: {
      a_crase: 'Le',
      about: 'À propos de nous',
      above_the_same: 'Exactement la même chose ci-dessus',
      action: 'Action',
      actionTemplates: "Modèles d'action",
      activate_date: "Date d'activation",
      active_automations: 'Automatisations actives',
      active_connections: 'Connexions actives',
      active_monitors: 'Moniteurs actifs',
      active_users: 'Utilisateurs actifs',
      ad: 'Annonce',
      add: 'Accession',
      add_option: 'Ajouter une option',
      add_payment: 'Ajouter un paiement',
      add_question: 'Ajouter une question',
      add_receipt: 'Ajouter un reçu',
      add_wallet: 'Ajouter un portefeuille',
      address: 'Adresse',
      admin: 'Administration',
      advance: 'Avance',
      affected: 'Atteint',
      after: 'Après',
      air_transport: 'Transport aérien',
      ajust_limite: 'Ajustement des limites',
      ajust_saldo: "Ajustement de l'équilibre",
      alert: 'Alerte',
      alerts: 'Alertes',
      all: 'Tous',
      all_departments: 'Tous les départements',
      allies_of: 'Alliés de',
      allRightsReserved: 'Tous droits réservés.',
      almost_there: 'Presque là',
      already_offset: "j'ai déjà compensé",
      already_registered: 'Déjà enregistré? Cliquez ici et entrez',
      amount: 'Valeur',
      amount_above_balance: 'Fonds insuffisants',
      and: "C'est",
      and_more: 'et plus',
      answer: 'Réponse',
      ao: 'au',
      approve: 'Approuver',
      April: 'Avril',
      asc: 'Ascendant',
      at: 'dans',
      August: 'Août',
      automations: 'Automatisations',
      awaiting_email_confirmation: 'En attente de confirmation par email !',
      b_order: 'Commande',
      b_orders: 'Ordres',
      back: 'Retourner',
      bairroInteresse: 'Quartier',
      balance: 'Équilibre',
      balance_available: 'Solde disponible',
      banned: 'banni',
      before_contact: 'Avant de nous contacter',
      binary: 'Double pointe',
      birth: 'Naissance',
      blind_man: 'Renversé',
      body_monthly:
        "Vos frais mensuels ont expiré et votre BOT TRADER a été inactivé, veuillez payer l'ordre de paiement et profiter à nouveau de vos avantages.",
      bonus_saldo_trader: 'Bonus de solde du commerçant',
      book_health: 'LivreSanté',
      but_possible_register: "Il est toujours possible de s'inscrire, mais vous ne serez lié à aucun ami parrain.",
      buy_volume: "Volume d'achat",
      buyer: 'Acheteur',
      by: 'par',
      bye: 'À plus tard',
      call: 'Appelé',
      call_to: 'Appeler pour',
      calls: 'Appels',
      cancel: 'Annuler',
      canceled: 'Annulé',
      career_plan: 'Cheminement de carrière',
      casa_verde_amarela: 'Maison Verte Jaune',
      cash_payment: 'Paiement en espèces',
      cash_payment_return: 'Restitution du paiement en espèces',
      cash_receipt: 'Ticket de caisse',
      change_indicant: 'Indicateur de changement',
      change_photo: 'Changer la photo',
      change_plan: 'Changer de forfait',
      check_payment: 'Paiement par chèque',
      check_payment_return: 'Retour du paiement par chèque',
      check_receipt: 'Réception par chèque',
      choose: 'Choisir',
      cidadeInteresse: 'Ville',
      city: 'Ville',
      claims: 'Réclamations',
      clean: 'Nettoyer',
      click_and_know: 'Cliquez et découvrez',
      click_to_copy_the_wallet: 'Cliquez pour copier le portefeuille',
      client: 'Client',
      clients: 'Clients',
      close: 'Fermeture',
      cnh: 'CNH (Licence)',
      cnhDate: "Date d'émission du CNH",
      cnpj: 'CNPJ',
      cnpj_invalid: 'CNPJ invalide',
      coin: 'Pièce de monnaie',
      color: 'Couleur',
      commercial: 'Commercial',
      company: "Nom de l'entreprise",
      complement: 'Complément',
      comporRenda: "Allez-vous gagner de l'argent avec quelqu'un ?",
      compRenda: 'Type de preuve de revenu',
      confirm_approve: "Confirmer l'approbation",
      confirm_delete: 'Confirmer la suppression',
      confirm_new_password: 'Confirmer le nouveau mot de passe',
      confirm_new_password_security: 'Confirmer le nouveau mot de passe de sécurité',
      confirm_password: "Confirmez le mot de passe d'accès",
      confirm_password_security: 'Confirmer le mot de passe de sécurité',
      confirm_your_new_password: 'Saisissez à nouveau votre nouveau mot de passe',
      confirmed: 'Confirmé',
      confirmed_email: 'E-mail confirmé',
      congratulations: 'Toutes nos félicitations',
      contact: 'Contact',
      contract: 'Contracter',
      copied: 'Copié',
      copy: 'Copie',
      copy_success: 'Copié avec succès !',
      correspondences: 'Correspondance',
      countries: 'Des pays',
      country: 'Pays',
      cpf: 'CPF',
      cpf_invalid: 'CPF invalide',
      create_order_success: 'Commande créée avec succès !',
      created: 'Enregistré',
      cred: 'Crédit',
      cred_binario: 'Crédit de deux points',
      cred_deb: 'Crédits/Débits',
      cred_game_donate: 'Jeu Faire un don de crédit',
      cred_ind_direta: 'Crédit de référence directe',
      cred_ind_indireta: 'Crédit de référencement indirect',
      cred_mandala: 'Crédit Mandala',
      cred_pagar_com_saldo: 'Paiement par crédit avec solde',
      cred_pontos: 'Points de crédit',
      cred_recb_trader: 'Trader de réception de crédit',
      cred_renovacao: 'Crédit de renouvellement',
      cred_saldo_disp: 'Solde disponible du crédit',
      cred_upgrade: 'Crédit de surclassement',
      credit_card_payment: 'Paiement par carte bancaire',
      credit_card_payment_return: 'Retour du paiement par carte bancaire',
      credit_card_receipt: 'Reçu de carte de crédit',
      current: 'Actuel',
      current_password: 'Mot de passe actuel',
      current_wallet: 'Portefeuille actuel',
      dashboard: 'Panneau de contrôle',
      data_added: 'Données ajoutées',
      data_saved: 'Données enregistrées',
      datas: 'Données',
      date: 'Date',
      date_first_installment: 'Date du premier paiement',
      ddi: 'Code postal',
      deb_de_trader: 'Dette commerciale',
      deb_game_donate: 'Jeu Faire un don Débit',
      deb_saldo: 'Solde débiteur',
      deb_saldo_disp: 'Solde disponible débiteur',
      deb_saldo_pendent: 'Débit du solde impayé',
      deb_val_indev: 'Montant indu au débit',
      debit_card_payment: 'Paiement par carte de débit',
      debit_card_payment_return: 'Retour du paiement par carte de débit',
      debit_card_receipt: 'Réception par carte de débit',
      debt: 'Dette',
      debt_td: 'Débit',
      December: 'Décembre',
      decImpRenda: "Déclarer l'impôt sur le revenu",
      deleted_document: 'Le document a été supprimé de votre dossier',
      department: 'Département',
      dependents: 'Personnes à charge',
      desc: 'Vers le bas',
      description: 'Description',
      details: 'Détails',
      dev_econom: 'Développement économique',
      developer: 'Développeur',
      directly_indicated: 'Directement indiqué',
      directly_indicateds: 'Référé directement',
      directs: 'Direct',
      district: 'Quartier / quartier',
      divorced: 'Divorcé',
      doa_men_receb: 'Don mensuel à la plateforme reçu',
      doacao_td: 'Don',
      doctorate_complete: 'Doctorat - Complet',
      doctorate_incomplete: 'Doctorat - Incomplet',
      document: 'Document',
      document_already_registered: 'Document déjà enregistré',
      documents: 'Documents',
      donate: 'Faire un don',
      donor: 'Donneur',
      dont_know_zip: 'Vous ne connaissez pas le code postal ? Cliquez ici',
      dou_fe: 'Je confirme que les informations ci-dessus sont correctes.',
      downloads: 'Telecharger des fichiers',
      drop_images: 'Cliquez ou faites glisser les images ici',
      due_date: 'Maturité',
      edit: 'Éditer',
      edit_option: "Modifier l'option",
      edit_question: 'Modifier la question',
      email: 'E-mail',
      email_already_registered: 'Email déjà enregistré',
      email_and_or_password_wrong: 'Mauvais email et/ou mot de passe !',
      email_for_login: 'Cet email sera votre identifiant',
      email_or_password_wrong: 'email ou mot de passe incorrect',
      employee: 'Employé',
      employees: 'Employés',
      empty: 'Vide',
      enter_your_new_password: 'Entrez votre nouveau mot de passe',
      entrada: "Est-ce qu'il a une valeur d'entrée ? Si OUI, quelle valeur",
      entries_until_today: 'Entrées à ce jour',
      error_confirm_password: 'La confirmation et le nouveau mot de passe doivent être identiques',
      error_email: "Format d'email invalide",
      error_password: 'Mot de passe incorrect',
      error_phone: 'Numéro de téléphone invalide',
      estadoInteresse: 'État',
      estimate: 'Estimé',
      exchange: 'Remplacement',
      expired: 'Expiré',
      extorno_td: 'Renversement',
      extra: 'Supplémentaire',
      extract: 'Extrait',
      extracts: 'Extraits',
      fantasy: 'Fantaisie',
      fatherName: 'Le nom du père',
      February: 'Février',
      fee: 'frais de retrait',
      feedback: 'Retour',
      feminine: 'Féminin',
      fgts: 'Valeur de votre FGTS',
      field_required: 'Achèvement obligatoire',
      final: 'Final',
      finance: 'Financier',
      financial: 'Financier',
      financial_payment: 'Paiement financier',
      financial_payment_return: 'Restitution du paiement financier',
      financial_receipt: 'Réception du financement',
      financing: 'Financement',
      financings: 'Financement',
      first_10_pages: '10 premières pages',
      fisica: 'Personne physique',
      for_year: 'Par an',
      forget_password: "J'ai oublié le mot de passe",
      forgot_password: 'Mot de passe oublié',
      form: 'Formulaire',
      form_of_payment: 'Forme de payement',
      found: 'Trouvé',
      founds: 'Trouvé',
      free: 'Gratuit',
      friend_indicated: 'Recommandé par mes amis',
      friend_indicateds: 'Recommandé par mes amis',
      fuel: 'Carburant',
      full_name: 'Nom et prénom',
      funcPublico: 'est un fonctionnaire',
      fundamental_complete: 'Fondamental - Complet',
      fundamental_incomplete: 'Fondamental - Incomplet',
      ganh_diario: 'Gains quotidiens',
      gender: 'Genre',
      grand_total: 'total',
      group: 'Groupe',
      has_withdraw_today: "Un retrait a déjà été demandé aujourd'hui",
      high: 'Haut',
      home: 'Commencer',
      how_create_binance_apikey: 'Comment créer une clé API sur Binance',
      how_it_works: 'Comment ça fonctionne',
      how_to_call: 'Comment veux-tu qu’on t’appelle ?',
      id_td: 'Référence directe',
      images: 'Images',
      in_construction: 'En cours de construction',
      in_order: 'En ordre',
      in_stock: 'En stock',
      indicant: 'Indicateur',
      indicated_by: 'Vous êtes référé par',
      indicated_not_found: "Le référent indiqué n'a pas été trouvé...",
      indicateds: 'Nominés',
      indirects: 'Indirect',
      info: "Lettres d'information",
      info_contact: 'Coordonnées',
      initial: 'Maison',
      insert_new: 'Entrez Nouveau',
      installments: 'Versements',
      intelligence: 'Intelligence',
      interesse: "Quelle est la région d'intérêt",
      invalid_contact: 'Format de contact invalide',
      invalid_images: 'Images invalides',
      invalid_token: 'jeton invalide',
      invite: 'Inviter',
      invite_friends: 'Invite tes amis',
      January: 'Janvier',
      July: 'Juillet',
      June: 'Juin',
      juridica: 'Personne morale',
      know_exact_result: 'Connaissez-vous la valeur exacte ?',
      lead: 'Ensemble, nous bâtirons un nouvel avenir.',
      learn_more: 'Savoir plus',
      left: 'Gauche',
      left_preferential: 'Préférant le côté gauche',
      legalNature: 'Nature juridique',
      level: 'Niveau',
      limite_80:
        'Vous avez atteint {{perc}} de votre limite.\n Effectuez une amélioration et évitez la suspension de vos revenus.',
      limite_excedido: 'Limite de débit dépassée',
      linear: 'Linéaire',
      link_email: "Cliquez sur le lien envoyé à l'e-mail enregistré !",
      link_invite_copy: 'Lien de partage copié avec succès',
      liquid: 'Liquide',
      list: 'Liste',
      loading: 'Chargement',
      location: 'Emplacement',
      locked: 'Bloqué',
      login: 'Entrer',
      logout: 'Sortir',
      low: 'Faible',
      male: 'Masculin',
      manage_users: 'gérer les utilisateurs',
      manager: 'Directeur',
      mandala: 'Mandalas',
      mandalas: 'Mandalas',
      manufacturer: 'Fabricant',
      March: 'Mars',
      maritalStatus: 'État civil',
      market: 'Marché',
      married: 'Marié',
      master_complete: 'Master - Terminé',
      master_incomplete: 'Master - Incomplet',
      max_payment: 'Paiement maximum',
      May: 'Peut',
      message: 'Message',
      messageNotSent: "Il n'a pas été possible d'envoyer votre message",
      messageSent: 'Votre message a été envoyé avec succès',
      middle_complete: 'Moyen - Complet',
      middle_incomplete: 'Moyen - Incomplet',
      min_amount_error: 'La valeur doit être au minimum :',
      min_withdraw: 'Montant minimum de retrait',
      missing: 'Manquant',
      mission: 'Mission',
      mission_vision_values: 'Mission, vision et valeurs',
      model: 'Modèle',
      monitors: 'Moniteurs',
      monthly: 'Paiement mensuel',
      motherName: 'Le nom de la mère',
      movements: 'Mouvements',
      msg_renovacao: 'Vous avez atteint votre limite de gains, renouvelez votre forfait.',
      my_account: 'Mon compte',
      my_wallet: 'Mon portefeuille',
      mystock: 'Mon stock',
      name: 'Nom',
      nascMaisVelhoRenda2: "Quelle est la date de naissance de l'aîné ?",
      nationality: 'Nationalité (Pays de naissance)',
      naturalness: 'Lieu de naissance (Ville de naissance)',
      nature: 'Nature',
      network: 'Réseau',
      new: 'Nouveau',
      new_call: 'Nouvel appel',
      new_financing: 'Nouveau financement',
      new_order: 'nouvel ordre',
      new_password: 'nouveau mot de passe',
      new_password_security: 'Nouveau mot de passe de sécurité',
      new_seller: 'Nouveau vendeur',
      new_wallet: 'Nouveau portefeuille',
      news: 'Nouvelles',
      next: 'Suivant',
      nick: 'Utilisateur en ligne)',
      nis: 'IRP/NIS',
      nis_already_registered: 'PIS/NIS déjà enregistré',
      no: 'Non',
      no_approve: 'Pas encore approuvé',
      no_delete: 'Ne pas supprimer',
      no_literate: 'Pas alphabétisé',
      no_repeat: 'Les données ne peuvent pas être répétées',
      no_results_found: 'aucun résultat trouvé',
      no_services_or_products_added: 'Aucun service ou produit ajouté',
      no_settings: 'Allez dans Paramètres et remplissez vos coordonnées.',
      none_friends_indicated: "Je n'ai pas encore parrainé d'amis",
      not_authorized: 'Pas autorisé',
      not_found: 'Pas trouvé',
      not_login: 'Impossible de vous connecter, veuillez vérifier vos informations et réessayer !',
      not_orders: 'Aucun ordre de paiement',
      not_permission: "Vous n'avez pas la permission d'accéder à cette page",
      not_registered: 'Pas encore inscrit? Cliquez ici et inscrivez-vous',
      not_results: 'Aucun enregistrement trouvé',
      not_self_register: "Impossible de s'inscrire",
      not_today_entry: "Nous n'avons toujours pas eu de billets aujourd'hui",
      notfound_ad: 'Aucune annonce trouvée',
      November: 'Novembre',
      number: 'Nombre',
      obs: 'commentaires',
      obs_pay:
        'Si votre courtier prélève des frais sur le montant envoyé, vous devez effectuer la correction manuellement en additionnant les montants de ces frais.',
      October: 'Octobre',
      of: 'dans',
      off: 'Désactivé',
      on_total: 'Du total',
      open: 'Ouvrir',
      opening: 'Ouverture',
      operation: 'Performance',
      ordenation: 'Commande',
      order: 'Commande',
      order_book: 'Carnet de commande',
      order_not_alowed_to_pay: 'Demande non autorisée de paiement avec solde',
      order_not_found: 'Commande introuvable',
      order_payed: 'Commande n° {{id}} payée avec succès',
      orders: 'Demandes',
      other: 'Autre',
      other_services_or_products: 'Autres services ou produits',
      paid: 'Payé',
      paid_date: 'Jour de paie',
      partial: 'Partiellement payé',
      passport: 'Passeport',
      password: 'Mot de passe',
      password_security: 'Mot de passe de sécurité',
      pay: 'Payer',
      pay_exact: 'Payez le montant en {{coin}}',
      pay_generate: 'Générer un paiement',
      pay_selected: 'Payer sélectionné',
      pay_value_in: 'Payez le montant en',
      pay_with_balance: 'Payer avec le solde',
      payable: 'Payer',
      payer: 'Payeur',
      payment: 'Paiement',
      ped_retirada: 'Demande de retrait',
      ped_retirada_gan_diario: 'Demande de retrait des gains journaliers',
      pendent: 'En attente',
      pendent_order: 'Vous avez des commandes en attente',
      people: 'Personnes',
      person: 'Personne',
      person_found_in_other_prefecture: 'Personne déjà inscrite dans une autre commune',
      personal: 'Les gars',
      personal_addresses: 'Adresses',
      personal_contacts: 'Contacts',
      personal_data: 'Données personnelles',
      personal_infos: 'Information',
      personal_pcva: 'Programme des Maisons Vertes et Jaunes',
      phone: 'Numéro de téléphone',
      photo: 'Photographier',
      pix: 'PIX',
      pix_payment: 'Paiement en PIX',
      pix_payment_return: 'Retour de paiement en PIX',
      pix_receipt: 'Réception dans PIX',
      pl_carreira: 'Cheminement de carrière',
      plan: 'Plat',
      plates: 'Assiettes',
      please_wait: "S'il vous plaît, attendez",
      plus: 'Plus',
      point: 'Indiquer',
      points: 'Points',
      portfolio: 'Portefeuille',
      pos_complete: 'Lato sensu - Complet',
      pos_incomplete: 'Lato sensu - Incomplet',
      prefecture: "L'hôtel de ville",
      prefecture_addresses: 'Adresses',
      prefecture_contacts: 'Contacts',
      prefecture_data: 'Données de la mairie',
      prefecture_infos: 'Information',
      prefectures: 'Mairies',
      prev: 'Précédent',
      price: 'Prix',
      print: 'Imprimer',
      privacy_policies: 'Les politiques de confidentialité',
      private_works: 'Travaux Privés',
      profession: 'Profession',
      profile: 'Profil',
      program: 'Programme',
      programs: 'Logiciel',
      project: 'Projet',
      projects: 'Projets',
      public_works: 'Travaux publics',
      purchase: 'Achat',
      qualify_binary: 'Qualification à deux points',
      qualquer: "N'importe lequel",
      quantity: 'Montant',
      quotation: 'Prix',
      read: 'Lire',
      receivable: 'Recevoir',
      recipient: 'Destinataire',
      recommendations: 'Recommandations',
      redefine_password: 'Redéfinir le mot de passe',
      register: 'Enregistrer',
      register_people: 'Enregistrement des personnes',
      register_wallet: 'Enregistrez un portefeuille pour effectuer des retraits',
      registers: 'Enregistrements',
      registers_users: 'Inscriptions des utilisateurs',
      reject: 'Rejeter',
      remaining: 'Restant',
      remove: 'Retirer',
      rendaIndividual: 'Revenu brut individuel',
      renew: 'Renouveler',
      renew_mandala: 'Renouvellement du Mandala',
      reply: 'Répondre',
      reports: 'Rapports',
      required_data_not_sent: 'Données requises non envoyées',
      resend_email: "Renvoyer l'email",
      residential: 'Résidentiel',
      responded: 'Répondu',
      restricao: 'Avez-vous des restrictions sur votre nom ? (CPS/Serasa)',
      result: 'Résultat',
      results: 'Résultats',
      resume_data: 'Résumé des données',
      revoked: 'Abrogé',
      rg: 'Inscription générale (identité)',
      rgAgency: 'Émetteur RG',
      rgDate: "Date d'émission du RG",
      rgUf: 'État de RG',
      right: 'Droite',
      right_preferential: 'Préférer le côté droit',
      roi: 'Gains quotidiens',
      sale: 'Vente',
      saq_duplicado: 'Double retrait',
      save: 'Sauver',
      schooling: 'Éducation',
      search: 'Recherche',
      secretary: 'secrétaire',
      security_password: 'Mot de passe de sécurité',
      see: 'Voir',
      see_advents: 'Voir les annonces',
      see_call: "Afficher l'appel",
      see_details: 'voir les détails',
      see_details_wallet: 'Afficher les détails du portefeuille',
      see_financing: 'Voir le financement',
      see_order: "Voir l'ordre",
      see_user: 'Afficher les utilisateurs',
      select_vehicle: 'Sélectionnez un véhicule',
      self_register: 'Registre',
      sell_volume: 'Volume des ventes',
      seller: 'Vendeur',
      send_call: 'Envoyer un appel',
      send_to: 'Envoyer à',
      September: 'Septembre',
      settings: 'paramètres',
      share_your_link: 'Partagez votre lien',
      show: "Afficher jusqu'à",
      show_from: 'Affichage à partir de',
      side: 'Côté',
      sign_in_to_start_your_session: 'Connectez-vous pour démarrer votre session',
      sign_up: 'Registre',
      single: 'Célibataire',
      sold: 'Vendu',
      state: 'État',
      status: 'Statut',
      strategies: 'Stratégies',
      street: 'Route',
      subject: 'Sujet',
      submit: 'Envoyer',
      submit_placeholder: 'Veuillez remplir votre email',
      subscribe: 'Inscrivez-vous pour recevoir nos actualités',
      subtitle: 'Légende',
      success_update_user: "L'utilisateur a été mis à jour avec succès !",
      success_wallet: 'Portefeuille mis à jour avec succès !',
      suggestions: 'Suggestions',
      superior_complete: 'Diplômé',
      superior_incomplete: 'Incomplet supérieur',
      supervisor: 'Superviseur',
      support: 'Soutien',
      support_materials: 'Matériel de support',
      suspended: 'Suspendu',
      symbol: 'Symbole',
      symbols: 'Symboles',
      system: 'Système',
      target: 'But',
      telegram_chat_id: 'Identifiant de discussion Telegram',
      tempoCarteira: 'Depuis combien de temps êtes-vous inscrit dans votre portefeuille ?',
      terms: "J'accepte les conditions d'utilisation",
      text_hint_binance:
        "Lors de la création de la clé API sur Binance, saisissez l'IP dans les restrictions d'accès :\n172.105.78.139\nSélectionnez ensuite les options :\n ACTIVER LES OPTIONS EUROPÉENNES\n ACTIVER LE SPOT & MARGIN TRADING",
      this_value_in: 'Cette valeur en',
      ticker_health: 'TickerSanté',
      title: 'Titre',
      to: "Jusqu'à",
      to_pay: 'Éteindre',
      to_receive: 'Recevable',
      today_entry: "L'entrée d'aujourd'hui",
      token_not_found_or_already_used: 'Jeton introuvable ou déjà utilisé',
      total: 'Total',
      total_entry: 'Entrée totale',
      traderbot: 'Commerçant de robots',
      transf_credito: 'Transférer du crédit',
      transf_debito: 'Transfert de débit',
      transfer_payment: 'Paiement par virement',
      transfer_payment_return: 'Retour du paiement par virement',
      transfer_receipt: 'Réception en virement',
      transparency: 'Transparence',
      tx_adm_cred: 'Administrer la taxe',
      tx_adm_deb: 'Administrer la taxe',
      tx_retirada: 'frais de retrait',
      tx_retirada_gan_diario: 'Frais de retrait des gains quotidiens',
      type: 'Taper',
      update: 'Mettre à jour',
      update_wallet: 'Mettre à jour le portefeuille',
      upgrade: 'Amélioration',
      used: 'Utilisé',
      user: 'Utilisateur',
      user_active: 'Utilisateur actif',
      user_already_exists: "L'utilisateur existe déjà!",
      user_and_or_password_wrong: "Mauvais nom d'utilisateur et / ou mot de passe!",
      user_inactive: 'Utilisateur inactif',
      user_not_found: 'Utilisateur non trouvé',
      user_not_logged: 'Utilisateur non connecté',
      user_or_password_wrong: 'identifiant ou mot de passe incorrect',
      users: 'Utilisateurs',
      users_list: "liste d'utilisateur",
      valid_images: 'Images valides',
      valorRenda2: 'Quelle est la valeur du deuxième revenu',
      value: 'Valeur',
      value_donate_on: 'Valeur du don en',
      value_financed: 'Montant financé',
      value_installment: 'Valeur du versement',
      values: 'Valeurs',
      vehicle: 'Véhicule',
      vehicles: 'Véhicules',
      view_order: "Voir l'ordre",
      vision: 'Vision',
      visitor: 'Visiteur',
      voucher: 'Bon',
      wait_confirm: 'En attente de confirmation',
      waiting: 'En attendant',
      waiting_email: "L'inscription a réussi, il ne vous reste plus qu'à confirmer votre email.",
      waiting_payment: 'En attente de paiement',
      wallet: 'Portefeuille',
      wallet_generate: 'Générer un portefeuille',
      wallets: 'Portefeuilles',
      want_donate: 'je veux faire un don',
      want_now_more_about: 'Je veux en savoir plus sur',
      warning: 'ATTENTION',
      we_are: 'Sont',
      webhooks: 'Webhooks',
      welcome: 'Accueillir',
      who_we_are: 'Qui nous sommes',
      who_we_are_subtitle: 'Une brève description du',
      widower: 'Veuf',
      will_expire_in: 'expirera dans',
      with: 'avec',
      withdraw: 'Retrait',
      withdraw_sended_email: 'Retrait demandé, vérifiez votre email et cliquez sur le lien pour autoriser !',
      withdrawal: 'Retraits',
      withdrawals: 'Retraits',
      year: 'Année',
      years: 'Années',
      yes: 'Oui',
      you_have: 'Tu as',
      your_country: 'Votre pays',
      your_dashboard: 'Ceci est votre panneau de contrôle.',
      your_link: 'Votre lien',
      zipcode: 'Code postal',
      withdrawal_sended: 'Retrait envoyé',
      withdrawal_pending: 'Retrait déjà demandé ! \nVeuillez attendre le paiement pour effectuer un autre retrait.',
      limite_80_top:
        'Vous avez atteint {{perc}} votre limite.\n Anticipez votre renouvellement et évitez de suspendre vos revenus.',
      game: 'Jeu',
      games: 'Jeux',
      winning_numbers: 'Numéros tirés',
      your_numbers: 'Vos numéros',
      add_numbers: 'Ajouter des numéros',
      next_draw: 'Prochain tirage',
      can_bet_until: "Vous pouvez parier jusqu'à",
      yout_bets: 'Vos paris',
      add_bet: 'Ajouter un pari',
      bet: 'Pari',
      bets: 'Paris',
      numbers: 'Nombres',
      special_numbers: 'Numéros spéciaux',
      resume: 'Résumé',
      choosed_numbers: 'Numéros choisis',
      choosed_special_numbers: 'Numéros spéciaux choisis',
      bets_finished: 'Paris réglés',
      prize_draw: 'Tirage au sort',
      balance_network: 'Solde du réseau',
      telegram: 'Télégramme',
      logs: 'Journaux',
      release_withdrawals: 'Libérer les retraits',
      bot_active: 'Libérer le robot',
      available: 'Disponible',
      verify: 'Vérifier',
      rescue: 'Sauvetage',
      awarded: 'Récompensé',
      rescued: 'Sauvé',
      repeat: 'Répéter',
      draw_date: 'Date du tirage',
      bet_date: 'Date du pari',
      drawn_numbers: 'Numéros tirés',
      awardeds_numbers: 'Numéros de prix',
      no_award: 'Aucun prix',
      you_hit_number: 'Vous avez bien obtenu le numéro {{qty}}',
      you_hit_numbers: 'Vous avez obtenu {{qty}} chiffres exacts',
      lottery: 'Loterie',
      lotteries: 'Loteries',
      balls: 'des balles',
      special_balls: 'Offres spéciales',
      levy: 'collection',
      major_awarded: 'plus de balles',
      hits: 'les coups',
      draw_due: 'Limite pour parier',
      close_game: 'Fermer le jeu',
      draw_due_greater_than_draw_date: "La limite de mise doit être d'au moins {{timeLimit}} minutes avant le tirage.",
      draw_date_less_than_now:
        "La date du tirage doit être supérieure à la date actuelle d'au moins {{timeLimit}} minutes.",
      balance_game: 'Solde pour les jeux',
      your_balance: 'Votre solde',
      your_balance_total: 'Votre solde total',
      game_package: '{{qty}} numéros (peut gagner {{prize}} fois)',
      choose_package: 'Choisissez le forfait',
      error_bet: "Impossible d'envoyer le pari",
      do_complete: 'Compléter',
      success_bet: 'Pari envoyé',
      you_bet_number: 'Vous pariez {{qty}} numéros',
      winner_msg: "et j'ai gagné {{prize}} fois",
      prize: 'Prix',
      balance_not_enough: 'Fonds insuffisants',
      addbalance: "Ajouter de l'équilibre aux jeux",
      convertbalance: 'Convertir les soldes',
      convertbalance_title: 'Convertir le solde disponible en solde de jeu',
      can_award: 'Tu peux gagner',
      emission: 'Émission',
      validation: 'Validation',
      participant: 'Participant',
      concourse: 'Concours',
      draw_hours: 'Temps de tirage',
      qty_dezenas: 'Quantité de dizaines',
      bet_amount: 'Montant du pari',
      share: 'Partager',
      shared: 'Prêt à partager',
      simulation: 'Simulation',
      valuePay: 'Montant à payer',
      quotas_paid_success: 'Paiement réussi',
      paids_today: "Paiements effectués aujourd'hui",
      no_paids_today: "Aucun paiement effectué aujourd'hui",
      perc: 'Pourcentage',
      define_percent: 'Définir le pourcentage',
      success_update: 'Mis à jour avec succès',
      define_percent_next_payment: 'Définir le pourcentage pour le paiement suivant',
      better: 'Consultant en paris',
      lottery_consultant: 'Consultant associé',
      create: 'Créer',
      doclose: 'Fermer',
      lottery_updated: 'Le tirage a été mis à jour',
      lottery_created: 'Le tirage a été créé',
      award_packages: 'Paquets de recompense',
      qty: 'Quantité',
      multiplier: 'Facteur',
      maxValue: 'Valeur maximale',
      limitRepeat: 'Limite de répetition',
      draw_day: 'Date du tirage',
      day: 'Jour',
      hour: 'Heure',
      minutes: 'Minutes',
      ytb_channel_id: 'ID de la chaîne YouTube',
      tutorial: 'Tutoriel',
      video_url: 'URL de la vidéo',
      tutorial_created: 'Tutoriel créé',
      tutorial_deleted: 'Le tutoriel a été supprimé',
      tutorial_error_delete: 'Impossible de supprimer le tutoriel',
      tutorial_updated: 'Le tutoriel a été mis à jour',
      tutorial_error_update: 'Impossible de mettre à jour le tutoriel',
      english: 'Anglais',
      spanish: 'Espagnol',
      portuguese: 'Portugais',
      select_language: 'Sélectionnez la langue',
      language: 'Langue',
      add_balance_games: "Ajouter de l'équilibre aux jeux",
      consultant_indication: 'Rendez-vous avec un consultant',
      mensal_activation: 'Activation mensuelle',
      your_bets: 'Vos paris',
      sunday: 'Dimanche',
      monday: 'Deuxième',
      tuesday: 'Troisième',
      wednesday: 'Quatrième',
      thursday: 'Cinquième',
      friday: 'Vendredi',
      saturday: 'Samedi',
      title_binary: 'Définir des pourcentages de points doubles pour chaque jour',
      ok_perc_change: 'Les pourcentages ont été modifiés avec succès',
      cred_deb_title: 'Solde créditeur ou débiteur',
      send: 'Envoyer',
      voucher_pendent: 'Bon en attente',
      leader_pendent: 'Leader en attente',
      voucher_approved: 'Bon approuvé',
      leader_approved: 'Leader approuvé',
      no_binary_at_today: 'Il n’y a pas de Binary enregistré ce jour-là',
      generate_file: 'Générer un fichier',
      withdrawals_file_created: 'Dossier de retrait créé',
      withdrawals_sended: 'Demandes de retrait envoyées',
      see_generated_files: 'Afficher les fichiers générés',
      files: 'Des dossiers',
      file: 'Déposer',
      conversion: 'Conversion entre soldes',
      betting_commission: 'Commission de paris',
      be_a_consultant: 'Devenez consultant en loterie et gagnez bien plus',
      msg_be_a_consultant:
        'La division des bénéfices mondiaux de Lotter.\nBonus de parrainage (sur les ventes de forfaits)\nBonus de points doubles\net bien plus encore.',
      auto_renovations: 'Renouvellements automatiques',
      auto_renove_binary: 'Auto-renouvellement à double point',
      auto_renove_quotas: 'Auto-renouvellement des objectifs (220%)',
      auto_renove_anual: 'Auto-renouvellement annuel',
      on: 'Connecté',
      change_success: 'Modification réussie',
      anual: 'Annuel',
      only_monday: 'Les retraits seront disponibles tous les lundis',
      transferency: 'Transfert',
      max_amount: 'Valeur maximum',
      info_better:
        "- Recevez 100 % de votre pack d'entrée et de renouvellements en CRÉDITS pour les paris.\n\n- Vous pouvez recharger de nouveaux crédits de paris quand vous le souhaitez.\n\n- Participez à chaque plan d'affaires et pouvez gagner grâce aux références directes et indirectes. , Bonus Binary, Unilevel, Plan de carrière et récompenses.",
      info_consultant:
        "- NE REÇOIT PAS DE CRÉDITS pour les paris, LE CONSULTANT FONDATEUR vend ses CRÉDITS pour les paris de son Pack d'Entrée et de renouvellements pour que The Lotter les négocie, et reçoit donc JUSQU'À 1% en semaine sur la valeur de son pack jusqu'à ce qu'il atteigne 220% et Vous pouvez ensuite renouveler si vous le souhaitez.\n\n- Vous pouvez recharger votre crédit de pari quand vous le souhaitez.\n\n- Participez à chaque plan d'affaires, en pouvant gagner grâce à des parrainages directs et indirects, un bonus Binary, Unilevel, Plan de carrière et récompenses.",
      confirm: 'Confirmer',
      send_new_token: 'Une fois la confirmation envoyée à votre email, cliquez sur votre email pour confirmer',
      email_resended: 'Confirmation envoyée à votre email, cliquez sur votre email pour confirmer',
      expired_send_new_token: 'Nouvelle confirmation envoyée à votre email, cliquez sur votre email pour confirmer',
      become_a_consultant: 'Mettez à niveau et obtenez bien plus',
      register_ok: 'Enregistré avec succès',
      ticket_number: 'Numéro de ticket',
      conversion_to_bet: 'Conversion en pari',
      conversion_balance_games: 'Conversion du solde en solde de jeu',
      conversion_balance_lotterprocoin: 'Conversion du solde en Activations',
      conversion_lotterprocoin_games: 'Conversion de Activations en solde de jeu',
      conta_corrente: 'Compte courant',
      balance_games: 'Équilibre du jeu',
      transf_debit_lotterProCoin: 'Transfert de solde Activations',
      deb_saldo_lotterProCoin: 'Débit du solde Activations',
      transf_credit_lotterProCoin: 'Transférer du crédit vers Activations',
      live_now: 'En direct',
      offline: 'En attente de dessin',
      tutorials: 'Tutoriels',
      msg_renove_binary:
        "Vous avez déjà terminé un autre mois de la dernière activation mensuelle et vous n'avez pas activé l'activation mensuelle automatique.\nC'est pourquoi votre Binary est inactif.\nPayez une demande d'ajout de solde de jeu d'un montant de 25,00 $ pour effectuer l'activation mensuelle. .",
      deb_balance_games: 'Décredit du solde de jeux',
      cred_saldo_lotterProCoin: 'Crédit du solde Activations',
      live_hour: 'Heure de début',
      choose_side_preferential: 'Choisissez le côté préféré (actuellement : {{side}})',
      count: 'Nombre',
      historics: 'Historique',
      balance_lotterProCoin: "Solde d'activation",
      msg_binary_tree_1: "Appuyez sur l'utilisateur souhaité pendant 1 seconde pour voir les informations.",
      msg_binary_tree_2: "Cliquez une fois sur l'utilisateur souhaité pour voir les suivants dans la liste binaire.",
      change_security_password: 'Changer le mot de passe de securité',
      info_pass_security: 'Le mot de passe de sécurité est utilisé pour effectuer des transactions financières',
      redefine_security_password: 'Changer le mot de passe de securité',
      redefined_password: 'Accédez à la réinitialisation du mot de passe avec succès !',
      click_to_enter: 'Cliquez pour entrer',
      title_banner: 'Bienvenue dans {{company}}, {{name}} !',
      descryption_banner:
        "Copiez votre lien d'inscription ci-dessous et partagez-le avec vos amis pour maximiser vos gains",
      no_binaries: "Impossible d'afficher le réseau de cet utilisateur",
      invalid_wallet: 'Portefeuille invalide',
      valid_wallet: 'Licence valide',
      wallet_usdc: 'Portefeuille USDC',
      comunicado_20240705:
        "L'équipe aibot est fière d'annoncer qu'après le début de la vente des licences, notre IA est prête à mener des opérations. \n Nous demandons à chacun de mettre à jour ses données et d'envoyer ses documents pour commencer un grand voyage. \n\nTout commence 24 heures après l'envoi et l'approbation des documents pour chaque compte. \n\n Profitez de l'opportunité d'acheter vos licences car elles sont limitées, et garantissez la double valeur au sein de votre courtier Binance ou Coinbase",
      customer: 'Client',
			estorno_comission: 'Annulation de commission',
    },
  },
}
export { messages }
