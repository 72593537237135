/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
// import Menu from '../../components/Menu/Menu'
import Pagination from '../../utils/pagination/Pagination'
import { getCalls } from '../../services/CallsService'
import { i18n } from '../../utils/translates/i18n'
import CallRow from './CallRow'

export default function Calls() {
  const defaultLocation = useLocation()
  const history = useHistory()

  const [calls, setCalls] = useState([])

  // PARA PAGINAÇÃO
  const token = sessionStorage.getItem('token')
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)
  const [lim, setLims] = useState(10)
  const [ordenation, setOrdenation] = useState('desc')
  const [status, setStatus] = useState('')

  const doGetCalls = async () => {
    const success = await getCalls(token, page, lim, search, status, ordenation)
    if (success.status) {
      setCalls(success.list.rows)
      setCount(success.list.count)
    }
  }

  useEffect(() => {
    doGetCalls()
  }, [token, page, lim, status, ordenation])

  function getPage(location) {
    if (!location) location = defaultLocation
    return new URLSearchParams(location.search).get('page')
  }

  useEffect(() => {
    return history.listen(location => {
      setPage(getPage(location))
    })
  }, [history])
  // FIM PARA PAGINAÇÃO

  const ReadCall = id => {
    sessionStorage.setItem('idCall', id)
    window.location = '/readcall'
  }

  return (
    <React.Fragment>
      {/* <Menu /> */}
      <main className="content">
        <>
          <div className="container-fluid">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <h2 className="h4">{i18n.t('calls')}</h2>
              </div>
            </div>
            <Pagination count={count} pageSize={lim}>
              <div className="input-group mx-3">
                <input
                  className="form-control"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={`${i18n.t('search')} ${i18n.t('by')} ${i18n.t('nick')}, ${i18n.t('subject')} ${i18n.t(
                    'or'
                  )} ${i18n.t('description')}`}
                />
              </div>
              <div className="col-md-4">
                <div className="input-group me-2">
                  <select className="form-select" value={status} onChange={e => setStatus(e.target.value)}>
                    <option value="">{i18n.t('status')}</option>
                    <option value="1">{i18n.t('waiting')}</option>
                    <option value="4">{i18n.t('closed')}</option>
                    <option value="9">{i18n.t('canceled')}</option>
                    <option value="15">{i18n.t('responded')}</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <select className="form-select me-2" value={ordenation} onChange={e => setOrdenation(e.target.value)}>
                  <option className="bg-light" value="asc">
                    {i18n.t('olds')}
                  </option>
                  <option className="bg-light" value="desc">
                    {i18n.t('recents')}
                  </option>
                </select>
              </div>
              <select className="form-select w-50" value={lim} onChange={e => setLims(e.target.value)}>
                <option className="bg-light" value="5">
                  5 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="10">
                  10 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="25">
                  25 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="50">
                  50 {i18n.t('registers')}
                </option>
                <option className="bg-light" value="100">
                  100 {i18n.t('registers')}
                </option>
              </select>
            </Pagination>
            <div className="card card-body bcall-0 shadow">
              {calls?.map((c, i) => (
                <CallRow c={c} key={i} ReadCall={ReadCall} />
              ))}
            </div>
          </div>
        </>
      </main>
    </React.Fragment>
  )
}
