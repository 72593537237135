import axios from './BaseService'

const ENDPOINT = `${process.env.REACT_APP_API_URL}/quotas`

export async function simulation(token, percent) {
  const endPoint = `${ENDPOINT}/simulation`
  const response = await axios.post(endPoint, { percent })
  return response.data
}

export async function payQuotas(token, percent) {
  const endPoint = `${ENDPOINT}/payquotas`
  const response = await axios.post(endPoint, { percent })
  return response.data
}

export async function getQuotasPaid(token) {
  const endPoint = `${ENDPOINT}/paid`
  const response = await axios.get(endPoint)
  return response.data
}
