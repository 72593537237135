import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './assets/css/style1.css'
import './assets/css/beholder.css'
import './assets/css/volt.css'
import './assets/fontawesome/css/fontawesome.css'
import './assets/fontawesome/css/brands.css'
import './assets/fontawesome/css/solid.css'

ReactDOM.render(
  <React.StrictMode>
		<Routes />
		<ToastContainer
      autoClose={5000}
      className="toast-container"
      position="top-right"
      closeButton={true}
      hideProgressBar={false}
      newestOnTop={true}
      draggable={false}
    />
  </React.StrictMode>,
  document.getElementById('root')
)
