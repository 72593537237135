import React from 'react';
import { FormateDate } from '../../utils/CustomFormatations';

/**
 * props:
 * - data
 */
export default function TelegramIdRow({ t }) {
	return (
		<>
			<tr>
				<td>{t.telegramId}</td>
				<td>{t.firstName}</td>
				<td>{t.lastName}</td>
				<td>{FormateDate(t.createdAt, true)}</td>
			</tr>
		</>
	);
}
