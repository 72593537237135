import axios from './BaseService'

const HYDRA_URL = `${process.env.REACT_APP_API_URL}/hydra/`

export async function getDashboard() {
  const response = await axios.get(HYDRA_URL + 'dashboard')
  return response.data
}

export async function getBalances() {
  const response = await axios.get(HYDRA_URL + 'balances')
  return response.data
}

export async function getPoolFastMatrix() {
  const response = await axios.get(HYDRA_URL + 'getpoolfastmatrix')
  return response.data
}

export async function doEditPool(pool) {
  const response = await axios.post(HYDRA_URL + 'doeditpool', pool)
  return response.data
}
